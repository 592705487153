import Link from "next/link";
import React from "react";
import styled from "styled-components";

import ChevronDown from "@/components/icons/ChevronDown/ChevronDown";
import ChevronUp from "@/components/icons/ChevronUp/ChevronUp";
import { MenuItem } from "@/components/layout/Header/Header";
import { P } from "@/components/typography";
import { COLORS } from "@/styles/theme";

type Props = {
  selected?: MenuItem;
  menuCols: MenuItem[];
  onChange: (x: MenuItem) => void;
};

const Wrapper = styled.div`
  flex: 1;
  padding: 32px;
  background-color: white;
  box-shadow: 0px 4px 12px var(--color-black-shadow-10);
  display: flex;
  border-radius: 8px;
  position: absolute;
  top: 10px;

  position: absolute;
  top: calc(100% + 5px);
  z-index: 999;
  right: 5px;
  width: calc(100% - 10px);

  ul {
    list-style: none;
    padding: 0;
    width: 100%;
  }

  > ul {
    > li {
      padding: 12px 0;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          margin: 0;
          @media (max-width: 767px) {
            font-size: 20px !important;
          }
        }
      }

      &.with-divider {
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
      }
    }

    .items {
      max-height: 1px;
      overflow: hidden;
      transition: 0.3s ease-in-out;
      @media (max-width: 767px) {
        transition: none;
      }

      li p {
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
    }

    .selected {
      .header p {
        color: ${({ theme }) => theme.colors.primary.normal};
      }

      .items {
        max-height: 100% !important;
        @media (max-width: 767px) {
          padding-left: 20px;
        }
      }
    }
  }
`;

//logic for donate button can be commented in if they want the button
export default function HeaderSubmenuMobile({
  selected,
  onChange,
  menuCols,
}: Props) {
  const generateColClass = (
    currentId: string,
    index: number,
    selectedId?: string,
  ) => {
    let className = "";

    if (selectedId === currentId) className = `${className} selected`;
    if (index < menuCols.length - 1) className = `${className} with-divider`;

    return className;
  };

  const donateItem = menuCols.find((menu) => menu.name === "Doneer nu");
  const otherItems = menuCols.filter((menu) => menu.name !== "Doneer nu");

  return (
    <Wrapper id="header-submenu-mobile">
      <ul>
        {otherItems.map((menu, index) => (
          <>
            {menu.children.length > 0 ? (
              <li
                key={menu.id}
                className={generateColClass(menu.id, index, selected?.id)}
                onClick={() => onChange(menu)}
              >
                <div className="header">
                  <P variant="bold" className="mr-2">
                    {menu.name}
                  </P>{" "}
                  <div>
                    {selected?.id === menu.id ? <ChevronUp /> : <ChevronDown />}
                  </div>
                </div>
                <ul key={index} className="items">
                  {menu.children.map((category) => {
                    if (category.status === "published") {
                      return (
                        <>
                          {category.children
                            .filter((items) => items.status === "published")
                            .map((item) => (
                              <li key={item.link}>
                                <P>
                                  <Link
                                    href={item.link}
                                    className={"[&_br]:hidden"}
                                    dangerouslySetInnerHTML={{
                                      __html: item.name,
                                    }}
                                  ></Link>
                                </P>
                              </li>
                            ))}
                        </>
                      );
                    }
                    return;
                  })}
                </ul>
              </li>
            ) : (
              <li key={menu.id} className={"with-divider"}>
                <div className="header">
                  <Link href={menu.url} target="_blank">
                    <P variant="bold">{menu.name}</P>
                  </Link>
                </div>
              </li>
            )}
          </>
        ))}
        {donateItem && (
          <li key={donateItem.id} className={"with-divider"}>
            <div className="header">
              <div
                className="w-full rounded-[8px] px-1"
                style={{
                  backgroundColor: COLORS.primary.normal,
                }}
              >
                <Link href={donateItem.url}>
                  <P className="m-[6px] text-center text-white">
                    {donateItem.name}
                  </P>
                </Link>
              </div>
            </div>
          </li>
        )}
      </ul>
    </Wrapper>
  );
}
